<template>
    <v-layout wrap>
        <v-flex xs12 class="text-end">
            <v-btn color="secondary" @click="back">Înapoi</v-btn>
        </v-flex>
        <v-flex xs12 ml-5 mt-5>
            <v-card>
                <v-card-title primary-title>
                    <v-flex xs12 class="font-weight-medium">
                        {{ data.number }} / {{ formatDate(data.display_date) }}
                    </v-flex>
                    <span class="display-1 font-weight-medium">{{
                        data.name
                    }}</span>
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-sheet color="#F1FAE5">
                        <v-layout pa-5>
                            <v-flex class="flex-grow-0 success--text title">{{
                                data.state
                            }}</v-flex>
                            <v-layout
                                class="font-weight-medium justify-end"
                                v-if="isHotarare && parse(data.meta).votes.for"
                            >
                                <v-flex class="flex-grow-0 ml-10 success--text">
                                    {{ parse(data.meta).votes.for }}/{{
                                        total(data.meta)
                                    }}
                                    Pentru</v-flex
                                >
                                <v-flex class="flex-grow-0 ml-10 error--text">
                                    {{ parse(data.meta).votes.against }}/{{
                                        total(data.meta)
                                    }}
                                    Împotriva</v-flex
                                >
                                <v-flex class="flex-grow-0 ml-10 text--primary">
                                    {{ parse(data.meta).votes.hold }}/{{
                                        total(data.meta)
                                    }}
                                    Abțineri</v-flex
                                >
                            </v-layout>
                        </v-layout>
                    </v-sheet>

                    <v-divider class="my-5" />

                    <v-layout class="ml-5">
                        <v-flex>
                            <v-btn
                                :disabled="!data.src"
                                color="primary"
                                :href="data.src"
                            >
                                <v-icon class="mx-2">mdi-download</v-icon>
                                Descarcă Atașament
                            </v-btn>
                        </v-flex>
                    </v-layout>

                    <v-divider class="my-5" />

                    <v-layout
                        wrap
                        class="mx-1"
                        v-if="isHotarare && data.documentation"
                    >
                        <v-card-text>
                            <v-flex xs12>
                                <v-chip label small color="primary">{{
                                    data.state
                                }}</v-chip>
                            </v-flex>
                            <p class="title text--primary">Documentație</p>
                            <div
                                class="ql-editor"
                                v-html="data.documentation"
                            ></div>
                        </v-card-text>
                    </v-layout>

                    <v-divider class="my-5" />

                    <v-layout
                        wrap
                        class="mx-1"
                        v-if="isHotarare && data.docs.length"
                    >
                        <v-card-text>
                            <p class="headline">Atașamentele Documentației</p>
                            <a
                                target="_blank"
                                style="text-decoration: none"
                                :href="doc.url"
                                v-for="(doc, index) in data.docs"
                                :key="index"
                            >
                                <v-card hover class="my-2">
                                    <v-card-text class="subtitle-2">{{
                                        doc.name
                                    }}</v-card-text>
                                </v-card>
                            </a>
                        </v-card-text>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import formatDate from "@/mixins/formatDate.js";
import back from "@/mixins/back";

export default {
    metaInfo: {
        title: "Înregistrare -"
    },
    name: "ViewRecord",
    mixins: [formatDate, back],
    data: () => ({
        data: {},
        slug: ""
    }),
    computed: {
        isHotarare() {
            return parseInt(this.data.registry_id) === 5;
        }
    },
    mounted() {
        const urlParams = new URLSearchParams(location.search);
        this.slug = urlParams.get("slug");
        this.getData();
    },
    methods: {
        total(meta) {
            const votes = this.parse(meta).votes;
            return Object.values(votes).reduce(
                (acc, curr) => (acc += parseInt(curr)),
                0
            );
        },
        parse(meta) {
            return JSON.parse(meta);
        },
        async getData() {
            const response = await this.axios.get(`/records/show/${this.slug}`);
            this.data = response.data;
        }
    }
};
</script>

<style scoped></style>
